import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Workspace } from "../../constants/data-types";

interface WorkspaceState {
  current: Workspace;
  available: Workspace[];
}

const initialState: WorkspaceState = {
  current: {
    id: null,
    name: "",
    is_default: false,
    ubico_attributed_value: 0,
    average_time_to_respond: ""
  },
  available: [],
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.current = action.payload;
    },
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      const currentWorkspace = action.payload?.find(
        (w: Workspace) => w.id === state.current?.id,
      );
      state.current = currentWorkspace;
      state.available.splice(0, state.available.length, ...action.payload);
    },
  },
});

export const { setWorkspace, setWorkspaces } = workspaceSlice.actions;

export default workspaceSlice.reducer;

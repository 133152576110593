import { CalendarMonth } from "@mui/icons-material";
import {
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import UbicoCard from "../../../../../../components/custom/Card";
import {
  UbicoSubscription
} from "../../../../../../constants/data-types";
import { RootState } from "../../../../../../redux/store";
import InvoiceDetails from "../management/invoice/InvoiceDetails";

const SubscriptionDetails: FC = (): ReactElement => {
  const theme = useTheme();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const startDate = new Date(
    subscription?.current_period_start * 1000,
  ).toDateString();
  const endDate = new Date(
    subscription?.current_period_end * 1000,
  ).toDateString();

  return (
    <Stack spacing={theme.spacing(2)}>
      <UbicoCard
        cardTitle="Upcoming invoice"
        cardSubtitle={
          <Stack direction={"row"} spacing={theme.spacing(1)}>
            <CalendarMonth
              fontSize="small"
              sx={{ color: theme.palette.text.secondary }}
            />
            <Typography variant="caption" color={"text.secondary"}>
              {startDate} - {endDate}
            </Typography>
          </Stack>
        }
      >
        <InvoiceDetails invoice={subscription?.next_invoice}/>
      </UbicoCard>
    </Stack>
  );
};

export default SubscriptionDetails;

import { useDispatch } from "react-redux";
import AuthService from "../services/authService";
import { openSnackbar } from "../redux/reducers/snackbarReducer";
import { setAuth } from "../redux/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    const resp = await AuthService.logout();

    if (resp.error) {
      dispatch(
        openSnackbar({
          message: "There was an error while logging out.",
          open: true,
          severity: "error",
        })
      );
    }
    dispatch(setAuth({ is_logged_in: false }));
    navigate("/login");
  }, [dispatch, navigate]);

  return logout;
};

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactElement, useState } from "react";
import UbicoButton from "../../../../../../components/custom/buttons/Button";
import { UbicoTextField } from "../../../../../../components/custom/textfields/TextField";
import { useSubscriptionCancellation } from "../../../../../../hooks/useSubscriptionCancellation";
import {
  CancellationFlowFeedbacks,
  CancellationFlowReasonDescriptions,
  CancellationFlowSteps,
} from "../../../constants/account-billing";

const SubscriptionCancelFeedback = (): ReactElement => {
  const { feedback, setFeedback, comment, setComment, setCurrentStep } =
    useSubscriptionCancellation();

  const theme = useTheme();

  const [error, setError] = useState('')

  const updateComment = (e) => {
    const comment = e.target.value
    setComment(comment)
    if(feedback === CancellationFlowFeedbacks.Other && !comment) {
      setError("Please add a comment to help us improve.")
    } else {
      setError("")
    }
  }

  return (
    <Stack spacing={theme.spacing(4)} width={"100%"}>
      <Stack spacing={theme.spacing(1)} width={"100%"}>
        <Typography>
          We're sorry to see you go. How did we fall short?
        </Typography>
        <FormLabel>Reason</FormLabel>
        <RadioGroup>
          {Object.entries(CancellationFlowReasonDescriptions).map(
            ([key, value]) => {
              const currentKey: CancellationFlowFeedbacks =
                key as CancellationFlowFeedbacks;
              return (
                <FormControlLabel
                  key={key}
                  label={value}
                  value={key}
                  control={
                    <Radio
                      key={key}
                      title={value}
                      checked={feedback === key}
                      onChange={() => setFeedback(currentKey)}
                    />
                  }
                />
              );
            },
          )}
        </RadioGroup>
      </Stack>
      <UbicoTextField
        label="Comment"
        multiline
        value={comment}
        InputLabelProps={{ shrink: true }}
        onChange={updateComment}
        error={Boolean(error)}
        minRows={3}
        helperText={error ? error : `${comment.length} / 250`}
        inputProps={{ maxLength: 250 }}
      />
      <Stack direction={"row"} width={"100%"} justifyContent={"flex-end"}>
        <UbicoButton
          disabled={!feedback || (feedback === CancellationFlowFeedbacks.Other && !comment)}
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          onClick={() =>
            setCurrentStep(CancellationFlowSteps.CancellationConfirm)
          }
        >
          Submit feedback
        </UbicoButton>
      </Stack>
    </Stack>
  );
};

export default SubscriptionCancelFeedback;
